import { Grid2 } from "@mui/material";
import isPast from "date-fns/isPast";
import isSameDay from "date-fns/isSameDay";
import React, { useMemo } from "react";

import { DayColumn } from "./styled-components";

const MemoizedAvailabilityDayColumn = ({
                               day,
                               isLastDay = false,
                               ...props
                           }: {
    day: Date;
    isLastDay?: boolean;
} & React.ComponentProps<typeof Grid2>) => {
    const styles = useMemo(
        () => ({
            marginTop: "-1px",
            backgroundColor: isSameDay(day, new Date())
                ? "rgba(0,0,0,0)"
                : isPast(day)
                    ? "rgba(0,0,0,0.01)"
                    : "#fff",
            borderBottom: "1px solid rgba(224,224,224)",
            borderTop: "1px solid rgba(224,224,224)",
            borderRight: isLastDay ? "1px solid rgb(224,224,224)" : "none",
        }),
        [day, isLastDay]
    );

    return <DayColumn style={styles} {...props} />;
};

export default React.memo(MemoizedAvailabilityDayColumn);
