/* eslint-disable no-mixed-spaces-and-tabs */
// TODO - Type 'any' needs to be fixed.
import {Grid2, Skeleton} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {subMinutes} from "date-fns";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import startOfWeek from "date-fns/startOfWeek";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useMediaQuery} from "react-responsive";

import {JOB, SKILLS} from "../../assets/constants";
import Error from "../../components/Error";
import Availability from "../scheduler/grid-scheduler/availability";
import {dayHeaderHeight, minTimeSlotHeight,} from "../scheduler/grid-scheduler/constants";
import MemoizedAvailabilityDayColumn from "../scheduler/grid-scheduler/memoized-availability-day-column";
import MemoizedDayColumnHeader from "../scheduler/grid-scheduler/memoized-day-column-header";
import {
  AvailabilityTimeSlotHeader,
  DayHeader,
  TimeLabel,
  TimeSlot,
} from "../scheduler/grid-scheduler/styled-components";
import {generateSkeletonVisibility, getDayNumber, getLocalTimeZone, hours,} from "../scheduler/grid-scheduler/utils";

function AvailabilityMap({
  shiftsByFilter,
  value,
  changeWeekAvailabilityStatus,
  height,
}: any) {
  const availabilityCalendarRef = useRef<HTMLDivElement>(null);
  const timeSlotHeight: number = useMemo(() => {
    const minHeight = minTimeSlotHeight;
    const returnValue = (height - dayHeaderHeight) / 26;
    return returnValue < minHeight ? minHeight : returnValue;
  }, [height]);

  const [formattedData, setFormattedData] = useState([]);
  const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(false);

  const weekStartDay = useSelector(
    (state: any) => state.user?.userData?.weekStartDay
  );

  const currentDate = useSelector(
    (state: any) => state.supervisor?.schedulerData?.currentDate
  );

  const startingDay = useMemo(
    () =>
      startOfWeek(new Date(currentDate), {
        weekStartsOn: getDayNumber(weekStartDay),
      }),
    [weekStartDay, currentDate]
  );

  useMediaQuery({ maxWidth: 1224 }, undefined, () =>
    setIsDrawerCollapsed(!isDrawerCollapsed)
  );

  const fetchFilterAndShiftDataStatus = useSelector(
    (state: any) => state.supervisor?.fetchFilterAndShiftData?.status
  );

  const getHourlyWorkerCountStatus = useSelector(
    (state: any) => state.supervisor?.getHourlyWorkerCount?.status
  );

  const fetchInitialDataErrorMessage = useSelector(
    (state: any) => state.supervisor?.fetchInitialData?.errorMessage
  );

  const allAvailabilityByJob = useSelector(
    (state: any) => state.supervisor.masterData.availabilityDataByJob
  );

  const allAvailabilityBySkill = useSelector(
    (state: any) => state.supervisor.masterData.availabilityDataBySkill
  );

  const allJobRoles = useSelector(
    (state: any) => state.supervisor.filterData.allJobRoles
  );
  const allSkills = useSelector(
    (state: any) => state.supervisor.filterData.allSkills
  );

  const showSkeleton = useMemo(() => {
    return (
      fetchFilterAndShiftDataStatus === "pending" ||
      getHourlyWorkerCountStatus === "pending" ||
      changeWeekAvailabilityStatus === "pending"
    );
  }, [
    fetchFilterAndShiftDataStatus,
    getHourlyWorkerCountStatus,
    changeWeekAvailabilityStatus,
  ]);

  // Memoized Skeleton Header loader for performance improvement
  const memoizedDayHeadersWithSkeleton = useMemo(() => {
    return Array.from({ length: 7 }).map((_, index) => {
      return (
        <MemoizedDayColumnHeader
          key={`day-header-skeleton-availability-${index}`}
          day={addDays(new Date(), 1)}
          width={"100%"}
        >
          <DayHeader>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <Skeleton
                variant="rounded"
                width={30}
                height={14}
                sx={{
                  backgroundColor: "#F5F5F5",
                  marginRight: 1,
                }}
              />
              <Skeleton
                variant="rounded"
                width={40}
                height={23}
                sx={{ backgroundColor: "#F5F5F5" }}
              />
            </Box>
          </DayHeader>
        </MemoizedDayColumnHeader>
      );
    });
  }, []);

  const memoizedTimeSlotsWithSkeleton = useMemo(() => {
    return Array.from({ length: 7 }).map((_, dayIndex) => {
      const day = addDays(new Date(), 1);
      const skeletonVisibility = generateSkeletonVisibility(
        hours.length,
        dayIndex
      );
      return (
        <MemoizedAvailabilityDayColumn key={dayIndex} day={day} width={"100%"} isLastDay={dayIndex === 6}>
          {hours.map((_, hourIndex) => (
            <TimeSlot height={timeSlotHeight} key={hourIndex}>
              {skeletonVisibility[hourIndex] ? (
                <Skeleton
                  key={`shift-skeleton--${dayIndex}-${hourIndex}`}
                  variant="rectangular"
                  width="100%"
                  animation="wave"
                  height={timeSlotHeight}
                  sx={{
                    backgroundColor: "#F5F5F5",
                    marginLeft: "12px",
                    marginRight: "24px",
                  }}
                />
              ) : null}
            </TimeSlot>
          ))}
        </MemoizedAvailabilityDayColumn>
      );
    });
  }, [timeSlotHeight]);

  useEffect(() => {
    if (value !== null) {
      if (shiftsByFilter === JOB) {
        if (allAvailabilityByJob) {
          const filteredAvailabilityByJob = allAvailabilityByJob.filter(
            (availabilityByJob: any) =>
              availabilityByJob.entityId === parseInt(value)
          );
          const dataByJob = filteredAvailabilityByJob.map((itemByJob: any) => {
            const resByJob = allJobRoles.find(
              (job: any) => job.id === itemByJob.entityId
            );
            const endDateByJob = new Date(itemByJob.endDate);
            const newItemByJob = { ...itemByJob };
            newItemByJob.endDate = subMinutes(endDateByJob, 1).toISOString();
            return { ...resByJob, ...newItemByJob };
          });

          const newFormattedDataByJob = dataByJob.map(function (item: any) {
            delete item.id;
            return item;
          });
          // Using maximum number of workers instead of max worker
          const maxNumberOfWorkers =
            newFormattedDataByJob.length > 0
              ? Math.max(
                  ...newFormattedDataByJob.map(
                    (item: any) => item.numberOfWorkers
                  )
                )
              : 0;
          const finalFormattedDataByJob = newFormattedDataByJob.map(
            (item: any) => {
              return {
                ...item,
                maxWorkers: maxNumberOfWorkers,
              };
            }
          );
          setFormattedData(finalFormattedDataByJob);
        }
      } else if (shiftsByFilter === SKILLS) {
        if (allAvailabilityBySkill) {
          const filteredAvailabilityBySkill = allAvailabilityBySkill.filter(
            (availabilityBySkill: any) =>
              availabilityBySkill.entityId === parseInt(value)
          );
          const dataBySkill = filteredAvailabilityBySkill.map(
            (itemBySkill: any) => {
              const resBySkill = allSkills.find(
                (skill: any) => skill.id === itemBySkill.entityId
              );
              const endDateBySkill = new Date(itemBySkill.endDate);
              const newItemBySkill = { ...itemBySkill };
              newItemBySkill.endDate = subMinutes(
                endDateBySkill,
                1
              ).toISOString();
              return { ...resBySkill, ...newItemBySkill };
            }
          );

          const formattedDataBySkill = dataBySkill.map(function (item: any) {
            delete item.id;
            return item;
          });
          // Using maximum number of workers instead of max worker
          const maxNumberOfWorkers =
            formattedDataBySkill.length > 0
              ? Math.max(
                  ...formattedDataBySkill.map(
                    (item: any) => item.numberOfWorkers
                  )
                )
              : 0;
          const finalFormattedDataBySkill = formattedDataBySkill.map(
            (item: any) => {
              return {
                ...item,
                maxWorkers: maxNumberOfWorkers,
              };
            }
          );
          setFormattedData(finalFormattedDataBySkill);
        }
      }
    } else {
      setFormattedData([]);
    }
  }, [
    value,
    shiftsByFilter,
    allAvailabilityByJob,
    allAvailabilityBySkill,
    allJobRoles,
    allSkills,
  ]);

  const getDataByHourAndDay = (dayIndex: number, hourIndex: number) => {
    const data = formattedData.find(
      (item: any) =>
        isSameDay(addDays(startingDay, dayIndex), new Date(item.startDate)) &&
        new Date(item.startDate).getHours() === hourIndex
    );
    return data ?? { numberOfWorkers: 0, maxWorkers: 0, hexColor: "" };
  };

  return (
    <Box
      ref={availabilityCalendarRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: height,
        minHeight: height,
        maxHeight: height,
        overflow: "scroll",
        paddingRight: "12px",
        flex: 1,
      }}
    >
      {fetchFilterAndShiftDataStatus === "error" && (
        <Error errorMessage={fetchInitialDataErrorMessage} />
      )}
      {getHourlyWorkerCountStatus === "error" && (
        <Error errorMessage={fetchInitialDataErrorMessage} />
      )}
      {getHourlyWorkerCountStatus !== "error" && (
        <Box key={`availability-${shiftsByFilter}`}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              alignItems: "flex-start",
            }}
          >
            {/* Time Labels and Indicators */}
            <Box
              sx={{
                minWidth: "80px",
                maxWidth: "80px",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  position: "sticky",
                  top: 0,
                  height: `${dayHeaderHeight}px`,
                  minHeight: `${dayHeaderHeight}px`,
                  backgroundColor: "rgba(255,255,255)",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  zIndex: 1001,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    lineHeight: 1.66,
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    color: "rgba(0,0,0,0.3)",
                    textAlign: "right",
                    paddingBottom: "12px",
                  }}
                >
                  {getLocalTimeZone()}
                </Typography>
              </Box>
              {hours.map((hour, index) => (
                <AvailabilityTimeSlotHeader
                  height={timeSlotHeight}
                  key={`availability-time-slot-header-${index}`}
                >
                  <TimeLabel>
                    <Box
                      sx={{
                        width: "100%",
                        marginRight: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.7rem",
                          lineHeight: 1.66,
                          fontWeight: 400,
                          fontFamily: "Roboto",
                          marginTop: -1,
                          marginRight: 1,
                          color: "rgba(0,0,0,0.6)",
                          textAlign: "right",
                        }}
                      >
                        {hour === "12 AM" ? "" : hour}
                      </Typography>
                    </Box>
                  </TimeLabel>
                </AvailabilityTimeSlotHeader>
              ))}
            </Box>

            {/* Availability Scheduler */}
            <Grid2
              container
              width={"100%"}
              sx={{
                flexGrow: 1,
              }}
            >
              {/* Day headers */}
              <Grid2
                container
                width={"100%"}
                sx={{
                  position: "sticky",
                  top: 0,
                  height: `${dayHeaderHeight}px`,
                  minHeight: `${dayHeaderHeight}px`,
                  backgroundColor: "rgba(255,255,255)",
                  boxShadow: "0px 2px 4px -2px rgba(0, 0, 0, 0.2)",
                  flexWrap: "nowrap",
                  zIndex: 1000,
                }}
              >
                {showSkeleton
                  ? memoizedDayHeadersWithSkeleton
                  : currentDate &&
                    Array.from({ length: 7 }).map((_, index) => {
                      const day = addDays(startingDay, index);
                      return (
                        <MemoizedDayColumnHeader
                          key={index}
                          day={day}
                          width={"100%"}
                        >
                          <DayHeader>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "flex-end",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "0.875rem",
                                  lineHeight: 1.05,
                                  fontWeight: isSameDay(day, new Date())
                                    ? 600
                                    : 400,
                                  fontFamily: "Roboto",
                                  color: isSameDay(day, new Date())
                                    ? "#2F4D8B"
                                    : "rgba(0,0,0,0.6)",
                                  marginRight: "0.5rem",
                                }}
                              >
                                {format(day, "EEE")}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "1.25rem",
                                  lineHeight: 1,
                                  fontWeight: isSameDay(day, new Date())
                                    ? 600
                                    : 500,
                                  fontFamily: "Roboto",
                                  color: isSameDay(day, new Date())
                                    ? "#2F4D8B"
                                    : "rgba(0,0,0,0.6)",
                                }}
                              >
                                {format(day, "d")}
                              </Typography>
                            </Box>
                          </DayHeader>
                        </MemoizedDayColumnHeader>
                      );
                    })}
              </Grid2>
              <Grid2
                container
                width={"100%"}
                sx={{
                  overflow: "hidden",
                  flexWrap: "nowrap",
                  // backgroundColor: "#f1f1f139"
                }}
              >
                {/* Time slots */}
                {showSkeleton
                  ? memoizedTimeSlotsWithSkeleton
                  : Array.from({ length: 7 }).map((_, dayIndex) => {
                      const day = addDays(startingDay, dayIndex);

                      return (
                        <MemoizedAvailabilityDayColumn
                          key={dayIndex}
                          day={day}
                          width={"100%"}
                          isLastDay={dayIndex === 6}
                        >
                          {hours.map((hour, hourIndex) => {
                            return (
                              <Availability
                                key={`worker-availability-${dayIndex}-${hourIndex}`}
                                data={getDataByHourAndDay(dayIndex, hourIndex)}
                                timeSlotHeight={timeSlotHeight}
                              />
                            );
                          })}
                        </MemoizedAvailabilityDayColumn>
                      );
                    })}
              </Grid2>
            </Grid2>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default AvailabilityMap;
