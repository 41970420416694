// MUI Imports
// Redux Imports
import { LinearProgress } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
// React Router Imports
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { Suspense } from "react";
//import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Router } from "react-router-dom";

import { light as theme } from "./theme";
import CrossSkillingDashboard from "./ts/components/cross-skilling-dashboard";
import MiniDrawer from "./ts/components/drawer";
import AdminConfiguration from "./ts/components/gat-admin";
import GlobalSnackBar from "./ts/components/GlobalSnackBar";
import WorkerActivity from "./ts/components/worker-activity";
import DeepLink from "./ts/features/DeepLink/DeepLink";
import Login from "./ts/features/user/login/login";
import WorkerSnackBar from "./ts/features/web-worker-app/Components/WorkerSnackBar";
import WorkersLogin from "./ts/features/web-worker-app/Login/WorkersLogin";
import AuthRoute from "./ts/utils/auth-route";
import DetectHome from "./ts/utils/detect-home";
import i18nFileGenerator from "./ts/utils/i18n-file-generator";
import PrivateRoute from "./ts/utils/private-route";
import withScrollToTop from "./ts/utils/scroll-to-top"; // Import HOC

const FactoryConfigCompaniesSettings = React.lazy(() =>
	import("./js/components/gat-admin/factory-config-companies-settings")
);
const WeeklySchedule = React.lazy(() =>
	import("./js/components/weekly-schedule")
);
const WorkcenterDashboard = React.lazy(() =>
	import("./js/components/workcenter-report")
);
const Dashboard = React.lazy(() =>
	import("./ts/components/attendance-reports")
);
const AbsenceReports = React.lazy(() =>
	import("./ts/components/absence-reports")
);
const AdminConfigSettings = React.lazy(() =>
	import(
		"./ts/components/gat-admin/admin-config-companies-settings/admin-config-settings"
	)
);
const InsightDashboard = React.lazy(() =>
	import("./ts/components/insight-dashboard")
);
const Jobs = React.lazy(() => import("./ts/components/jobs"));
const Skills = React.lazy(() => import("./ts/components/skills"));
const Workcenters = React.lazy(() => import("./ts/components/workcenters"));
const Workers = React.lazy(() => import("./ts/components/workers"));

const Certification = React.lazy(() => import("./ts/features/certifications"));
const SkillMatrix = React.lazy(() => import("./ts/features/skill-matrix"));
const ForgotPassword = React.lazy(() =>
	import("./ts/features/user/forgot-password")
);
const ResetPassword = React.lazy(() =>
	import("./ts/features/user/reset-password")
);
const Form = React.lazy(() =>
	import("./ts/features/web-worker-app/Components/Form")
);
const PrivacyPolicy = React.lazy(() =>
	import("./ts/features/web-worker-app/Components/PrivacyPolicy")
);

const RegisterHere = React.lazy(() =>
	import("./ts/features/web-worker-app/Components/RegisterHere")
);
const DownloadApp = React.lazy(() =>
	import("./ts/features/web-worker-app/DownloadApp")
);
const Settings = React.lazy(() =>
	import("./ts/features/web-worker-app/Settings")
);
const WorkerProfile = React.lazy(() =>
	import("./ts/features/web-worker-app/WorkerProfile")
);
const WorkersLanding = React.lazy(() =>
	import("./ts/features/web-worker-app/WorkersLanding")
);
const WorkersMain = React.lazy(() =>
	import("./ts/features/web-worker-app/WorkersMain")
);
const ShiftAcknowledgement = React.lazy(() =>
	import("./ts/features/web-worker-app/shift-acknowledgement")
);
const NotFound = React.lazy(() => import("./ts/components/NotFound"));
const ShiftSwaps = React.lazy(() => import("./ts/features/worker-requests"));

const DemoSetup = React.lazy(() => import("./ts/components/demo-setup"));

const themeConstructed = createTheme(theme);

function App() {
	const authenticated = useSelector((state) => state.user.authenticated);
	const workerAuthenticated = useSelector(
		(state) => state.worker.authenticated
	);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themeConstructed}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					{/* REMOVE HELMET and CHATSCRIPT */}
					{/* <Helmet>
						<script
							type='text/javascript'
							id='hs-script-loader'
							async
							defer
							src='//js.hs-scripts.com/21474340.js'
						/>
					</Helmet> */}
					<div className='App'>
						<Suspense fallback={<LinearProgress />}>
							<BrowserRouter
								future={{
									v7_relativeSplatPath: true,
									v7_startTransition: true,
								}}
							>
								<Routes>
									<Route
										element={
											<AuthRoute
												authenticated={authenticated}
												workerApp={false}
											/>
										}
									>
										<Route path='/login' element={<Login />} />
										<Route
											path='/forgot-password'
											element={<ForgotPassword />}
										/>
										<Route path='/verifyEmail' element={<ResetPassword />} />
									</Route>

									<Route
										element={
											<PrivateRoute
												authenticated={authenticated}
												workerApp={false}
											/>
										}
									>
										<Route path='/' element={<DetectHome />} />
										<Route path='/flex-schedule' element={<MiniDrawer />} />
										<Route path='/shift' element={<MiniDrawer />} />
										<Route path='/manage-workers' element={<Workers />} />
										<Route path='/manage-skills' element={<Skills />} />
										<Route path='/manage-jobs' element={<Jobs />} />
										<Route path='/workcenters' element={<Workcenters />} />
										<Route path='/reports' element={<Dashboard />} />
										<Route
											path='/absence-reports'
											element={<AbsenceReports />}
										/>
										<Route
											path='/worker-activity'
											element={<WorkerActivity />}
										/>
										<Route
											path='/admin-configuration'
											element={<AdminConfiguration />}
										/>
										<Route path='/insight' element={<InsightDashboard />} />
										<Route path='/certifications' element={<Certification />} />
										<Route
											path='/admin-settings'
											element={<AdminConfigSettings />}
										/>
										<Route path='/day-report' element={<Dashboard />} />
										<Route
											path='/workcenter-report'
											element={<WorkcenterDashboard />}
										/>
										<Route path='/skills-matrix' element={<SkillMatrix />} />
										<Route
											path='/cross-skilling'
											element={<CrossSkillingDashboard />}
										/>
										<Route
											path='/full-time-schedule'
											element={<WeeklySchedule />}
										/>
										<Route path='/worker-requests' element={<ShiftSwaps />} />
									</Route>

									{/* Only load route for demo reset for dev and demo branch */}
									{(process.env.REACT_APP_ENV === "demo" ||
										process.env.REACT_APP_ENV === "dev") && [
										<Route
											key={"factory-settings"}
											path='/factory-settings'
											element={<FactoryConfigCompaniesSettings />}
										/>,
										<Route
											key={"demo-setup"}
											path='/demo'
											element={<DemoSetup />}
										/>,
									]}
									{/* For dev use only */}
									{process.env.REACT_APP_ENV === "dev" && (
										<Route
											path='/i18n-file-generator'
											element={<i18nFileGenerator />}
										/>
									)}
									<Route path='/wl' element={<WorkersLogin />} />
									<Route
										element={
											<PrivateRoute
												authenticated={workerAuthenticated}
												workerApp={true}
											/>
										}
									>
										<Route path='/w' element={<WorkersLanding />} />
										<Route path='/wd' element={<WorkersMain />} />
										<Route path='/ws' element={<Settings />} />
										<Route path='/wp' element={<WorkerProfile />} />
										<Route path='/wa' element={<ShiftAcknowledgement />} />
										<Route path='/dl' element={<DeepLink />} />
										<Route path='/download-app' element={<DownloadApp />} />
										<Route path='/help-support' element={<Form />} />
										<Route path='/privacy-policy' element={<PrivacyPolicy />} />
										<Route path='/register-here' element={<RegisterHere />} />
										<Route path='/' element={<NotFound />} />
									</Route>
								</Routes>
							</BrowserRouter>
						</Suspense>
						<WorkerSnackBar />
						<GlobalSnackBar />
					</div>
				</LocalizationProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
