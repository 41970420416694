import {
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	Switch,
	Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import LoadingButton from "../../../../../../design/wilya-loading-button";
import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import { updateAllowedWorkCentersForOverlapShifts } from "../../../../store/gat-admin-slice";
import AllowedWorkcenters from "./allowed-workcenters";

interface WorkCenter {
	id: string | number;
	name: string;
	isOverlapSignupEnabled: boolean;
}

interface OverlapShiftsProps {
	newShiftSettings: {
		overlappingShiftAllowed: boolean;
		overlapWindowTime: number;
	};
	setNewShiftSettings: React.Dispatch<React.SetStateAction<any>>;
	//loading: boolean;
	oldData: any;
	newData: any;
	resetChanges: () => void;
	handleSave: () => Promise<void>;
}

const OverlapShifts: React.FC<OverlapShiftsProps> = ({
	newShiftSettings,
	setNewShiftSettings,
	//loading,
	oldData,
	newData,
	resetChanges,
	handleSave,
}) => {
	const dispatch = useDispatch();
	const {
		getLocationSettingsStatus,
		currentLocationData,
		locationSettingsObj,
		updateAllowedWorkCentersForOverlapShiftsStatus,
	} = useGatAdminConfigData();

	const [loading, setLoading] = useState(false);

	const [selectedWorkcenters, setSelectedWorkcenters] = useState<string[]>([]);

	const { wc, locationWorkcenters } = useMemo(
		() => ({
			wc: currentLocationData?.workCenters.reduce(
				(acc: { [key: number]: string }, wc: WorkCenter) => {
					acc[wc.id as number] = wc.name;
					return acc;
				},
				{}
			),
			locationWorkcenters: currentLocationData?.workCenters,
		}),
		[currentLocationData?.workCenters]
	);

	const handleWorkcenterChange = (
		event: SelectChangeEvent<typeof selectedWorkcenters>
	) => {
		const value = event.target.value;
		if (value.includes("all")) {
			if (selectedWorkcenters.length === locationWorkcenters?.length) {
				setSelectedWorkcenters([]);
			} else {
				setSelectedWorkcenters(
					locationWorkcenters?.map((wc: { id: number }) => wc.id) || []
				);
			}
		} else {
			setSelectedWorkcenters(value as string[]);
		}
	};

	const handleDelete = (
		event: React.MouseEvent,
		workcenterToDelete: string
	) => {
		event.stopPropagation();
		if (locationSettingsObj["overlappingShiftSingup.Enabled"].value === false) {
			return;
		}
		setSelectedWorkcenters(
			selectedWorkcenters.filter((wc) => wc !== workcenterToDelete)
		);
	};

	const handleUpdate = useCallback(() => {
		if (!currentLocationData) return;
		dispatch(
			updateAllowedWorkCentersForOverlapShifts({
				companyId: currentLocationData?.companyId,
				locationId: currentLocationData?.id,
				workcenterIds: selectedWorkcenters,
			})
		);
	}, [currentLocationData, selectedWorkcenters, dispatch]);

	const getEnabledWorkcenters = useCallback(
		(workcenters: WorkCenter[] = []) => {
			return workcenters
				.filter((wc) => wc.isOverlapSignupEnabled)
				.map((wc) => wc.id.toString());
		},
		[]
	);

	const hasDataChanged = useCallback((oldData: any, newData: any) => {
		return JSON.stringify(oldData) !== JSON.stringify(newData);
	}, []);

	const handleSettingsSave = useCallback(async () => {
		try {
			setLoading(true);
			const settingsChanged = hasDataChanged(oldData, newData);
			const enabledWorkcenters = getEnabledWorkcenters(locationWorkcenters);
			const workcentersChanged = hasDataChanged(
				selectedWorkcenters,
				enabledWorkcenters
			);

			if (settingsChanged) {
				await handleSave();
			}

			if (workcentersChanged && newShiftSettings.overlappingShiftAllowed) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				await handleUpdate();
			}
		} catch (error) {
			console.error("Failed to save settings:", error);
			setLoading(false);
		}
	}, [
		oldData,
		newData,
		selectedWorkcenters,
		locationWorkcenters,
		newShiftSettings.overlappingShiftAllowed,
		handleSave,
		handleUpdate,
		hasDataChanged,
		getEnabledWorkcenters,
	]);

	useEffect(() => {
		if (locationWorkcenters) {
			setSelectedWorkcenters(
				locationWorkcenters
					?.filter(
						(wc: { isOverlapSignupEnabled: boolean }) =>
							wc.isOverlapSignupEnabled
					)
					.map((wc: { id: number }) => wc.id)
			);
		}
	}, [locationWorkcenters]);

	useEffect(() => {
		if (getLocationSettingsStatus === "fulfilled") {
			setLoading(false);
		}
	}, [getLocationSettingsStatus]);

	const oldOverlapData = {
		overlappingShiftAllowed: oldData["overlappingShiftAllowed"],
		overlapWindowTime: oldData["overlapWindowTime"],
	};

	const newOverlapData = {
		overlappingShiftAllowed: newData["overlappingShiftAllowed"],
		overlapWindowTime: newData["overlapWindowTime"],
	};

	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Allow Full-Time Workers to pick up Overtime Shifts that overlap with
						their Full-Time Shift
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Full Time Workers can be notified of Overtime Shifts that overlap
						with their Full Time Shift to extend their regular work
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{loading || getLocationSettingsStatus === "pending" ? (
						<Skeleton width={50} height={40} />
					) : (
						<Switch
							checked={newShiftSettings.overlappingShiftAllowed ?? false}
							onChange={(e) => {
								setNewShiftSettings((prevSettings: any) => ({
									...prevSettings,
									overlappingShiftAllowed: e.target.checked,
									overlapWindowTime: e.target.checked
										? 30
										: prevSettings.overlapWindowTime,
								}));
							}}
						/>
					)}
				</Box>
			</Box>

			{newShiftSettings.overlappingShiftAllowed ? (
				<Box
					sx={{
						marginTop: "32px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "16px",
									color: "rgba(0, 0, 0, 0.87)",
									marginLeft: "8px",
								}}
							>
								Allowed overlap with the Full-Time Shift
							</Typography>
						</Box>

						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginLeft: "8px",
							}}
						>
							{getLocationSettingsStatus === "pending" ? (
								<Skeleton width={60} height={50} />
							) : (
								<Select
									id='days-to-accept-shift'
									variant='filled'
									sx={{
										"& .MuiInputBase-input": {
											background: "#FFF",
											padding: "8px",
										},
										width:
											newShiftSettings.overlapWindowTime > 90 ? "70px" : "60px",
									}}
									onChange={(e) => {
										setNewShiftSettings((prevSettings: any) => ({
											...prevSettings,
											overlapWindowTime: Number(e.target.value),
										}));
									}}
									value={newShiftSettings.overlapWindowTime ?? 30}
								>
									<MenuItem value={30}>30</MenuItem>
									<MenuItem value={60}>60</MenuItem>
									<MenuItem value={90}>90</MenuItem>
									<MenuItem value={120}>120</MenuItem>
								</Select>
							)}
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontWeight: 400,
									fontSize: "16px",
									color: "rgba(0, 0, 0, 0.87)",
									marginLeft: "8px",
								}}
							>
								minutes
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<AllowedWorkcenters
							loading={
								loading ||
								updateAllowedWorkCentersForOverlapShiftsStatus === "pending" ||
								getLocationSettingsStatus === "pending"
							}
							selectedWorkcenters={selectedWorkcenters}
							handleWorkcenterChange={handleWorkcenterChange}
							handleDelete={handleDelete}
							locationWorkcenters={locationWorkcenters}
							wc={wc}
							updateStatus={updateAllowedWorkCentersForOverlapShiftsStatus}
						/>
					</Box>
				</Box>
			) : null}
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					paddingTop: "16px",
					paddingBottom: "12px",
				}}
			>
				<Button
					variant='outlined'
					disabled={
						(JSON.stringify(oldOverlapData) ===
							JSON.stringify(newOverlapData) &&
							JSON.stringify(selectedWorkcenters) ===
								JSON.stringify(
									locationWorkcenters
										?.filter(
											(wc: { isOverlapSignupEnabled: boolean }) =>
												wc.isOverlapSignupEnabled
										)
										.map((wc: { id: string }) => wc.id)
								)) ||
						loading ||
						getLocationSettingsStatus === "pending" ||
						updateAllowedWorkCentersForOverlapShiftsStatus === "pending"
					}
					onClick={() => {
						setSelectedWorkcenters(
							locationWorkcenters
								?.filter(
									(wc: { isOverlapSignupEnabled: boolean }) =>
										wc.isOverlapSignupEnabled
								)
								.map((wc: { id: string }) => wc.id)
						);
						resetChanges();
					}}
				>
					Discard all new changes
				</Button>
				<LoadingButton
					variant='contained'
					disabled={
						(JSON.stringify(oldOverlapData) ===
							JSON.stringify(newOverlapData) &&
							JSON.stringify(selectedWorkcenters) ===
								JSON.stringify(
									locationWorkcenters
										?.filter(
											(wc: { isOverlapSignupEnabled: boolean }) =>
												wc.isOverlapSignupEnabled
										)
										.map((wc: { id: string }) => wc.id)
								)) ||
						loading ||
						getLocationSettingsStatus === "pending" ||
						updateAllowedWorkCentersForOverlapShiftsStatus === "pending"
					}
					sx={{ marginLeft: "16px" }}
					loading={
						loading ||
						updateAllowedWorkCentersForOverlapShiftsStatus === "pending"
					}
					onClick={handleSettingsSave}
					loadingIndicator={
						<CircularProgress size={16} sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
					}
				>
					Save
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default OverlapShifts;
