/* eslint-disable simple-import-sort/imports */
import {Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography,} from "@mui/material";
import React from "react";
import Highlight from "../../../../Highlight";
import Loading from "../../../../../components/Loading";
import useInsightWorkerDetailsByLevelController
    from "../../../controllers/use-insight-worker-details-levels-controller";
import BuildIcon from "@mui/icons-material/Build";
import {SkillLevel, WorkerInsightModel,} from "../../../slices/insight-report-types";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SkillCell from "../../../../../design/skill-representaion-cell";

interface SkillWithLevel {
  skillName: string;
  skillId: number;
  level: number;
  minSkillLevel: number | null;
  isPrimaryJobSkill: boolean;
  isInterested: boolean;
}

interface SkillsAccordionProps {
  skills: SkillWithLevel[];
  heading: string;
}

const WorkerInsightDetailsSkillsGapTab = ({
  allSkillLevels,
  loading,
}: {
  allSkillLevels: SkillLevel[];
  loading: boolean;
}) => {
  const [
    { allSkills, searchText, expanded },
    { setSearchText, setExpanded },
  ]: any = useInsightWorkerDetailsByLevelController();

  const handleAccordianChange = (level: string) => {
    setExpanded((prevExpanded: string[]) => {
      if (prevExpanded.includes(level)) {
        return prevExpanded.filter(
          (accordionName: string) => accordionName !== level
        );
      } else {
        return [...prevExpanded, level];
      }
    });
  };

  const displaySkillsBySkillLevel = () => {
    let otherSkills: SkillsAccordionProps = {
      heading: "Other Skills",
      skills: [],
    };
    let primaryJobQualifiedSkills: SkillsAccordionProps = {
      heading: "Skills Qualified for the Primary Job",
      skills: [],
    };
    let primaryJobUnqualifiedSkills: SkillsAccordionProps = {
      heading: "Skills with gap",
      skills: [],
    };
    let interestedSkills: SkillsAccordionProps = {
      heading: "Interested Skills",
      skills: [],
    };

    primaryJobQualifiedSkills = allSkills.reduce(
      (acc: SkillsAccordionProps, skill: SkillWithLevel) => {
        const minLevel = skill.minSkillLevel ?? -1;
        const level = skill.level ?? -1;
        if (
          minLevel !== -1 &&
            level >= minLevel &&
          skill.isPrimaryJobSkill
        ) {
          acc.skills.push(skill);
        }
        return acc;
      },
      primaryJobQualifiedSkills
    );

    primaryJobUnqualifiedSkills = allSkills.reduce(
      (acc: SkillsAccordionProps, skill: SkillWithLevel) => {
        const minLevel = skill.minSkillLevel ?? -1;
        const level = skill.level ?? -1;
        if (
          skill.isPrimaryJobSkill &&
          ((level < minLevel && minLevel !== -1) || minLevel === -1)
        ) {
          acc.skills.push(skill);
        }
        return acc;
      },
      primaryJobUnqualifiedSkills
    );

    interestedSkills = allSkills.reduce(
      (acc: SkillsAccordionProps, skill: SkillWithLevel) => {
        if (skill.isInterested) {
          acc.skills.push(skill);
        }
        return acc;
      },
      interestedSkills
    );

    otherSkills = allSkills.reduce(
      (acc: SkillsAccordionProps, skill: SkillWithLevel) => {
        if (
          !primaryJobQualifiedSkills.skills.includes(skill) &&
          !primaryJobUnqualifiedSkills.skills.includes(skill) &&
          !interestedSkills.skills.includes(skill)
        ) {
          acc.skills.push(skill);
        }
        return acc;
      },
      otherSkills
    );

    return (
      <>
        {primaryJobQualifiedSkills.skills.length > 0 && (
          <Box>
            <Accordion
              disableGutters
              sx={{
                boxShadow: "none",
                borderTop: "none",
                borderBottom: "none",
                borderLeft: "none",
                borderRight: "none",
                "&.MuiAccordion-root": {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
              expanded={
                searchText.length > 0 ||
                expanded.includes("primaryJobQualifiedSkills")
              }
              onChange={() =>
                handleAccordianChange("primaryJobQualifiedSkills")
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"primaryJobQualifiedSkills-content"}
                id={"primaryJobQualifiedSkills-header"}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontFamily: "Roboto",
                      color: "rgba(0,0,0,0.87)",
                    }}
                  >
                    {primaryJobQualifiedSkills.heading}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      fontFamily: "Roboto",
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >{`${primaryJobQualifiedSkills.skills.length} Skill${
                    primaryJobQualifiedSkills.skills.length > 1 ? "s" : ""
                  }`}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{ display: "grid" }}>
                {primaryJobQualifiedSkills.skills?.sort(
                    (a: SkillWithLevel, b: SkillWithLevel) =>
                        a.skillName.localeCompare(b.skillName)
                ).map(
                  (skill: SkillWithLevel) => {
                    return (
                      <Box
                        key={skill.skillId}
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          flexDirection: "column",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            marginBottom: "8px",
                            width: "100%",
                          }}
                        >
                          {skill.level !== null ? (
                            <SkillCell
                              value={Number(allSkillLevels[skill.level].level)}
                              height={16}
                              width={16}
                              backgroundWidth={"20px"}
                              backgroundHeight={"20px"}
                              skillLevels={allSkillLevels}
                              inSelect={false}
                            />
                          ) : (
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                width: "20px",
                                textAlign: "center",
                              }}
                            >
                              --
                            </Typography>
                          )}
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: 400,
                              marginLeft: "8px",
                              whiteSpace: "normal",
                              wordBreak: "break-word",
                              overflowWrap: "break-word",
                              textAlign: "left",
                            }}
                          >
                            <Highlight
                              value={skill.skillName ?? "-"}
                              searchText={searchText}
                            />
                          </Typography>
                        </Box>
                          <Box
                              sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  backgroundColor: "#cccccc40",
                                  borderRadius: "4px",
                                  padding: "8px",
                                  marginLeft: "28px",
                              }}
                          >
                              <Typography
                                  sx={{
                                      fontFamily: "Roboto",
                                      fontSize: "12px",
                                      lineHeight: "16px",
                                      color: "rgba(0,0,0,0.6)",
                                      paddingRight: "16px",
                                      whiteSpace: "nowrap",
                                  }}
                              >
                                  Level Required
                              </Typography>
                              {skill.minSkillLevel !== null && (
                                  <SkillCell
                                      value={Number(
                                          allSkillLevels[skill.minSkillLevel].level
                                      )}
                                      height={13}
                                      width={13}
                                      backgroundWidth={"17px"}
                                      backgroundHeight={"17px"}
                                      skillLevels={allSkillLevels}
                                      inSelect={false}
                                  />
                              )}

                              <Typography
                                  sx={{
                                      fontFamily: "Roboto",
                                      fontSize: "12px",
                                      lineHeight: "16px",
                                      color: "rgba(0,0,0,0.87)",
                                      marginLeft: skill.minSkillLevel !== null ? "8px" : "0px",
                                  }}
                              >
                                  {
                                      skill.minSkillLevel !== null ?
                                      allSkillLevels[skill.minSkillLevel]?.name ?? "-"
                                          : "Not Specified"
                                  }
                              </Typography>
                          </Box>
                        <Divider
                          sx={{
                            width: "100%",
                            marginTop: "8px",
                          }}
                        />
                      </Box>
                    );
                  }
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {primaryJobUnqualifiedSkills.skills.length > 0 && (
          <Box style={{ fontWeight: "bold" }}>
            <Accordion
              disableGutters
              sx={{
                boxShadow: "none",
                borderTop: "none",
                borderBottom: "none",
                borderLeft: "none",
                borderRight: "none",
                "&.MuiAccordion-root": {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
              expanded={
                searchText.length > 0 ||
                expanded.includes("primaryJobUnqualifiedSkills")
              }
              onChange={() =>
                handleAccordianChange("primaryJobUnqualifiedSkills")
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"primaryJobUnqualifiedSkills-content"}
                id={"primaryJobUnqualifiedSkills-header"}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontFamily: "Roboto",
                      color: "rgba(0,0,0,0.87)",
                    }}
                  >
                    {primaryJobUnqualifiedSkills.heading}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      fontFamily: "Roboto",
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >{`${primaryJobUnqualifiedSkills.skills.length} Skill${
                    primaryJobUnqualifiedSkills.skills.length > 1 ? "s" : ""
                  }`}</Typography>
                </Box>
              </AccordionSummary>

              <AccordionDetails style={{ display: "grid" }}>
                {primaryJobUnqualifiedSkills.skills?.sort(
                    (a: SkillWithLevel, b: SkillWithLevel) =>
                        a.skillName.localeCompare(b.skillName)
                ).map(
                  (skill: SkillWithLevel) => {
                    return (
                      <Box
                        key={skill.skillId}
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          flexDirection: "column",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            marginBottom: "8px",
                            width: "100%",
                          }}
                        >
                          {skill.level !== null ? (
                            <SkillCell
                              value={Number(allSkillLevels[skill.level].level)}
                              height={16}
                              width={16}
                              backgroundWidth={"20px"}
                              backgroundHeight={"20px"}
                              skillLevels={allSkillLevels}
                              inSelect={false}
                            />
                          ) : (
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                width: "20px",
                                textAlign: "center",
                              }}
                            >
                              --
                            </Typography>
                          )}
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: 400,
                              marginLeft: "8px",
                              whiteSpace: "normal",
                              wordBreak: "break-word",
                              overflowWrap: "break-word",
                              textAlign: "left",
                            }}
                          >
                            <Highlight
                              value={skill.skillName ?? "-"}
                              searchText={searchText}
                            />
                          </Typography>
                        </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                              backgroundColor: "#cccccc40",
                              borderRadius: "4px",
                              padding: "8px",
                              marginLeft: "28px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "rgba(0,0,0,0.6)",
                                paddingRight: "16px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Level Required
                            </Typography>
                              {skill.minSkillLevel !== null && (
                            <SkillCell
                              value={Number(
                                allSkillLevels[skill.minSkillLevel].level
                              )}
                              height={13}
                              width={13}
                              backgroundWidth={"17px"}
                              backgroundHeight={"17px"}
                              skillLevels={allSkillLevels}
                              inSelect={false}
                            />)}
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "rgba(0,0,0,0.87)",
                                marginLeft: skill.minSkillLevel !== null ? "8px" : "0px",
                              }}
                            >
                              {skill.minSkillLevel !== null ?
                                  allSkillLevels[skill.minSkillLevel]?.name ?? "-":
                                    "Not Specified"
                              }
                            </Typography>
                          </Box>
                        <Divider
                          sx={{
                            width: "100%",
                            marginTop: "8px",
                          }}
                        />
                      </Box>
                    );
                  }
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {otherSkills.skills.length > 0 && (
          <Box style={{ fontWeight: "bold" }}>
            <Accordion
              disableGutters
              sx={{
                boxShadow: "none",
                borderTop: "none",
                borderBottom: "none",
                borderLeft: "none",
                borderRight: "none",
                "&.MuiAccordion-root": {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
              expanded={
                searchText.length > 0 || expanded.includes("otherSkills")
              }
              onChange={() => handleAccordianChange("otherSkills")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"otherSkills-content"}
                id={"otherSkills-header"}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontFamily: "Roboto",
                      color: "rgba(0,0,0,0.87)",
                    }}
                  >
                    {otherSkills.heading}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      fontFamily: "Roboto",
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >{`${otherSkills.skills.length} Skill${
                    otherSkills.skills.length > 1 ? "s" : ""
                  }`}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{ display: "grid" }}>
                {otherSkills.skills?.sort(
                    (a: SkillWithLevel, b: SkillWithLevel) =>
                        a.skillName.localeCompare(b.skillName)
                ).map((skill: SkillWithLevel) => {
                  return (
                    <Box
                      key={skill.skillId}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        flexDirection: "column",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          flexDirection: "row",
                          marginBottom: "8px",
                          width: "100%",
                        }}
                      >
                        {skill.level !== null ? (
                          <SkillCell
                            value={Number(allSkillLevels[skill.level].level)}
                            height={16}
                            width={16}
                            backgroundWidth={"20px"}
                            backgroundHeight={"20px"}
                            skillLevels={allSkillLevels}
                            inSelect={false}
                          />
                        ) : (
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: 400,
                              width: "20px",
                              textAlign: "center",
                            }}
                          >
                            --
                          </Typography>
                        )}
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: 400,
                            marginLeft: "8px",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            textAlign: "left",
                          }}
                        >
                          <Highlight
                            value={skill.skillName ?? "-"}
                            searchText={searchText}
                          />
                        </Typography>
                      </Box>
                      <Divider
                        sx={{
                          width: "100%",
                          marginTop: "8px",
                        }}
                      />
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {interestedSkills.skills.length > 0 && (
          <Box style={{ fontWeight: "bold" }}>
            <Accordion
              disableGutters
              sx={{
                boxShadow: "none",
                borderTop: "none",
                borderBottom: "none",
                borderLeft: "none",
                borderRight: "none",
                "&.MuiAccordion-root": {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
              expanded={
                searchText.length > 0 || expanded.includes("interestedSkills")
              }
              onChange={() => handleAccordianChange("interestedSkills")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"interestedSkills-content"}
                id={"interestedSkills-header"}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontFamily: "Roboto",
                      color: "rgba(0,0,0,0.87)",
                    }}
                  >
                    {interestedSkills.heading}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      fontFamily: "Roboto",
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >{`${interestedSkills.skills.length} Skill${
                    interestedSkills.skills.length > 1 ? "s" : ""
                  }`}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{ display: "grid" }}>
                {interestedSkills.skills?.sort(
                    (a: SkillWithLevel, b: SkillWithLevel) =>
                        a.skillName.localeCompare(b.skillName)
                ).map((skill: SkillWithLevel) => {
                  return (
                    <Box
                      key={skill.skillId}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        flexDirection: "column",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          flexDirection: "row",
                          marginBottom: "8px",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: 400,
                            marginLeft: "8px",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            textAlign: "left",
                          }}
                        >
                          <Highlight
                            value={skill.skillName ?? "-"}
                            searchText={searchText}
                          />
                        </Typography>
                      </Box>
                      <Divider
                        sx={{
                          width: "100%",
                          marginTop: "8px",
                        }}
                      />
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: "10px",
      }}
    >
      <Box sx={{ padding: "8px 16px", width: "100%" }}>
        {(allSkills?.length > 0 ||
          searchText.length > 0) && (
          <TextField
            label="Search"
            placeholder="Skill Name"
            fullWidth
            variant="filled"
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            size="small"
            value={searchText}
            slotProps={{
              input: {
                endAdornment: searchText !== undefined &&
                  searchText.length > 0 && (
                    <CancelOutlinedIcon
                      sx={{ cursor: "pointer", color: "#92918f" }}
                      onClick={() => setSearchText("")}
                    />
                  ),
              },
            }}
            sx={{
              "& .MuiFilledInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:after": {
                borderBottom: "none",
              },
              //paddingTop: "4px",
              marginBottom: "16px",
              // marginLeft: "24px",
            }}
          />
        )}
        {allSkills?.length > 0 ? (
          <>{displaySkillsBySkillLevel()}</>
        ) : (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              mt: 10,
            }}
          >
            <BuildIcon sx={{ color: "#6c6d6e", fontSize: "40px" }} />
            No skills {searchText.length > 0 ? "found" : "available"}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WorkerInsightDetailsSkillsGapTab;
