import {Box, Typography} from "@mui/material";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import React, { MouseEvent,useRef, useState } from "react";

import {minDayColumnWidth} from "./constants";
import { SelectionBox } from "./styled-components";
import { canSelectTime } from "./utils";

interface TimeSelectionProps {
    dayColumnRef: React.RefObject<HTMLDivElement>;
    onSelectionComplete: (startY: number, endY: number) => void;
    canSelectTimeProps: {
        dayIndex: number;
        todayPosition: number;
        currentTime: number;
        timeSlotHeight: number;
    };
    startingDay: Date;
}

const TimeSelection = ({
                           dayColumnRef,
                           onSelectionComplete,
                           canSelectTimeProps,
                           startingDay,
}: TimeSelectionProps) => {
    const [selection, setSelection] = useState<{ startY: number; endY: number } | null>(null);
    const isSelecting = useRef(false);
    const {
        dayIndex,
        todayPosition,
        currentTime,
        timeSlotHeight,
    } = canSelectTimeProps;

    const handleMouseDown = (event: MouseEvent) => {
        if (!dayColumnRef.current) return;

        isSelecting.current = true;
        const columnRect = dayColumnRef.current.getBoundingClientRect();
        const startY = event.clientY - columnRect.top;
        if (canSelectTime(dayIndex, todayPosition, currentTime, startY - timeSlotHeight)) {
            setSelection({ startY, endY: startY });
        } else {
            isSelecting.current = false;
        }
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (!isSelecting.current || !selection || !dayColumnRef.current) return;

        const columnRect = dayColumnRef.current.getBoundingClientRect();
        const endY = event.clientY - columnRect.top;

        setSelection({ startY: selection.startY, endY });
    };

    const handleMouseUp = () => {
        if (!selection || !isSelecting.current) return;

        isSelecting.current = false;
        onSelectionComplete(selection.startY, selection.endY);
        setSelection(null); // Reset after selection is done
    };

    const getTimeStringOnSelection = (startY: number, endY: number) => {
        const day = addDays(startingDay, canSelectTimeProps.dayIndex);
        const startHour = Math.floor(startY / timeSlotHeight);
        const startMinutes = Math.floor((startY % timeSlotHeight) / timeSlotHeight * 60);
        const endHour = Math.floor(endY / timeSlotHeight);
        const endMinutes = Math.floor((endY % timeSlotHeight) / (timeSlotHeight / 60));
        const startDate = new Date(day);
        const endDate = new Date(day);

        // Check if we are on same hour as of current time
        const currentTime = new Date();
        let sameHour = false;
        const anchorHour = startDate.getTime() > endDate.getTime() ? endDate.getHours() : startDate.getHours();
        if (isSameDay(day, currentTime) && anchorHour === currentTime.getHours()) {
            const currentMinutes = currentTime.getMinutes();
            if (startMinutes < currentMinutes) {
                sameHour = true;
            }
        }
        const startMinutesRounded = sameHour ? Math.ceil(startMinutes / 30) * 30 : Math.round(startMinutes / 30) * 30;
        const endMinutesRounded = Math.round(endMinutes / 30) * 30;

        startDate.setHours(startHour, startMinutesRounded, 0, 0);
        endDate.setHours(endHour, endMinutesRounded, 0, 0);

        if (endDate.getTime() >= startDate.getTime()) {
            return `${format(startDate, "h:mm a")} - ${format(endDate, "h:mm a")}`;
        } else {
            return `${format(endDate, "h:mm a")} - ${format(startDate, "h:mm a")}`;
        }
    };

    return (
        <Box
            ref={dayColumnRef}
            sx={{
                position: "relative",
                width: "12px",
                height: "100%",
                zIndex: 999,
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={ isSelecting.current ? handleMouseMove : undefined}
            onMouseUp={ isSelecting.current ? handleMouseUp : undefined}
        >
            <Box sx={{
                position: "absolute",
                width: isSelecting.current ? `${2*minDayColumnWidth}px` : "12px",
                marginLeft: isSelecting.current ? `${-(minDayColumnWidth-6)/2}px` : 0,
                height: "100%",
                zIndex: 999,
            }}>
                {selection && (
                    <SelectionBox
                        sx={{
                            marginLeft: isSelecting.current ? `${(minDayColumnWidth-6)/2}px` : 0,
                            top: `${Math.min(selection.startY, selection.endY)}px`,
                            height: `${Math.abs(selection.endY - selection.startY)}px`,
                        }}
                    >
                        <Typography sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#FFFFFF",
                            fontWeight: 500,
                        }}>{getTimeStringOnSelection(selection.startY - timeSlotHeight , selection.endY - timeSlotHeight)}</Typography>
                    </SelectionBox>
                )}
            </Box>
        </Box>
    );
};

export default TimeSelection;