import addDays from "date-fns/addDays";
import isSameDay from "date-fns/isSameDay";
import startOfWeek from "date-fns/startOfWeek";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {APPLIED_FILTERS_HEIGHT} from "../../../../components/filters/constants";
import {resetMostRecentShiftStartTime} from "../../../../components/scheduler/store/scheduler-slice";
import {dayHeaderHeight, minShiftWidth, minTimeSlotHeight, shiftSpacing} from "../constants";
import {generateSkeletonVisibility, getDayNumber} from "../utils";
import useCurrentTime from "./use-current-time";

const useGridSchedulerController = (height: number, openCreateNewShiftDialog: any): any => {
  const anchorEl = useRef<any>(null);

  const dayColumnRefs = useRef<(HTMLDivElement | null)[]>(Array(7).fill(null));

  const dispatch = useDispatch();
  const selectedShift = useRef<any>(undefined);
  const weekStartDay = useSelector(
    (state: any) => state.user?.userData?.weekStartDay
  );

  const calendarRef = useRef(null); // Main grid ref

  const currentDate =
    useSelector((state: any) => state.supervisor?.schedulerData?.currentDate) ??
    new Date().toISOString();
  const mostRecentShiftStartTime = useSelector(
    (state: any) => state.supervisor?.schedulerData?.mostRecentShiftStartTime
  );
  const userRoles = useSelector((state: any) => state.user.userData.userRoles);
  const allJobRoles = useSelector(
    (state: any) => state.supervisor?.filterData?.allJobRoles
  );
  const allSkills = useSelector(
    (state: any) => state.supervisor?.filterData?.allSkills
  );
  const fetchFilterAndShiftData = useSelector(
    (state: any) => state.supervisor.fetchFilterAndShiftData.status
  );
  const canCreateOrEditShift = fetchFilterAndShiftData === "fulfilled";

  const startingDay = useMemo(
    () =>
      startOfWeek(new Date(new Date(currentDate).setHours(0,0,0,0)), {
        weekStartsOn: getDayNumber(weekStartDay),
      }),
    [weekStartDay, currentDate]
  );

  const endingDay = useMemo(() => addDays(startingDay, 7), [startingDay]);

  const isWeekView = useSelector((state: any) => state.supervisor.weekView);

  const [visible, setVisible] = useState(false);

  const timeSlotHeight: number = useMemo(() => {
    const minHeight = minTimeSlotHeight;
    // overall screen height without filter and day header divided by number of time slots in a day
    const returnValue = (height - dayHeaderHeight - APPLIED_FILTERS_HEIGHT) / 26;
    return returnValue < minHeight ? minHeight : returnValue;
  }, [height]);

  const toggleVisibility = (val: any) => {
    if (!val) {
      anchorEl.current = null;
      selectedShift.current = undefined;
    }
    setVisible(val);
  };

  const openShiftPopover = (ref: any, shift: any) => {
    selectedShift.current = shift;
    anchorEl.current = ref;
    setVisible(true);
  };

  const isLastDayOfWeek = (dayIndex: number) => {
    return getDayNumber(weekStartDay) + 1 === dayIndex;
  };

  const currentTime = useCurrentTime(timeSlotHeight);

  const [defaultCalendarScrollValue, setDefaultCalendarScrollValue] = useState(timeSlotHeight);
  const [recentShiftScrollValue, setRecentShiftScrollValue] = useState(0);

  useEffect(() => {
    try {
      if (currentDate && currentTime && startingDay && endingDay) {
        const currentTimePosition = currentTime;
        const isSameWeekOrDay = isWeekView
            ? new Date().getTime() >= startingDay.getTime() && new Date().getTime()  <= endingDay.getTime()
            : isSameDay(new Date(currentDate), new Date());
        setDefaultCalendarScrollValue(isSameWeekOrDay ? currentTimePosition : timeSlotHeight - 4);
      }
    } catch (e) {
      // Do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeekView, currentDate, startingDay, endingDay, timeSlotHeight]);

  useEffect(() => {
    try {
      if (dispatch && currentDate && currentTime && startingDay && endingDay && mostRecentShiftStartTime !== "") {
        const mostRecentShiftStartPosition = new Date(mostRecentShiftStartTime).getHours()*timeSlotHeight;
        const shiftAndCurrentDateOnDayOrWeek = isWeekView
            ? new Date(mostRecentShiftStartTime).getTime() >= startingDay.getTime() && new Date(mostRecentShiftStartTime).getTime()  <= endingDay.getTime()
            : isSameDay(new Date(mostRecentShiftStartTime), new Date(currentDate));
        if (shiftAndCurrentDateOnDayOrWeek) {
          setRecentShiftScrollValue(mostRecentShiftStartPosition);
          dispatch(resetMostRecentShiftStartTime());
        }
      }
    } catch (e) {
      // Do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeekView, currentDate, startingDay, endingDay, timeSlotHeight, mostRecentShiftStartTime]);


  const allShiftsFromAPI = useSelector(
    (state: any) => state.supervisor?.schedulerData?.filteredShiftsData
  );

  const allShifts = useMemo(() => {
    // Show only those shifts where jobId got a valid job present in allJobRoles
    // and shiftSkills array contain valid skills present in allSkills
    return allShiftsFromAPI.filter((shift: any) => {
      const validJobRole = allJobRoles.find(
        (jobRole: any) => jobRole.id === shift.jobId
      );
      const validSkills = shift.shiftSkills?.map((skill: any) =>
        allSkills.find((s: any) => s.id === skill.skillId)
      )?.length > 0 ?? false;
      return validJobRole || validSkills;
    });
  }, [allShiftsFromAPI, allJobRoles, allSkills]);

  const overlappingDaysShifts = useMemo(() => {
    return allShifts.filter((shift: any) => {
      const startDate = new Date(shift.startDateTime);
      const endDate = new Date(shift.endDateTime);
      return (
        (endDate > startingDay &&
          startDate < startingDay &&
          startDate.getDay() !== endDate.getDay()) ||
        (startDate > startingDay &&
          endDate < endingDay &&
          startDate.getDay() !== endDate.getDay())
      );
    });
  }, [allShifts, startingDay, endingDay]);

  // Past week shift in overlapping shifts
  const pastWeekShifts = useMemo(() => {
    return overlappingDaysShifts.filter((shift: any) => {
      const startDate = new Date(shift.startDateTime);
      const endDate = new Date(shift.endDateTime);
      return startDate.getTime() < startingDay.getTime() && endDate.getTime() > startingDay.getTime();
    });
  }, [overlappingDaysShifts, startingDay]);

  // Filter all shifts based on start and end date
  const filteredShifts = useMemo(() => {
    return allShifts.filter((shift: any) => {
      const startDate = new Date(shift.startDateTime);
      return startDate >= startingDay && startDate <= endingDay;
    });
  }, [allShifts, startingDay, endingDay]);

  const uniqueShifts = useMemo(() => {
    return Array.from(new Set(filteredShifts.map((a: any) => a.id))).map(
      (id) => {
        return filteredShifts.find((a: any) => a.id === id);
      }
    );
  }, [filteredShifts]);

  // sort shifts by startDateTime
  const sortedShifts = useMemo(() => {
    return uniqueShifts.sort((a: any, b: any) => {
      const aStart = new Date(a.startDateTime);
      const bStart = new Date(b.startDateTime);
      return aStart.getTime() - bStart.getTime();
    });
  }, [uniqueShifts]);

  const sortedPassedWeekShifts = useMemo(() => {
    return pastWeekShifts.sort((a: any, b: any) => {
      const aStart = new Date(a.startDateTime);
      const bStart = new Date(b.startDateTime);
      return aStart.getTime() - bStart.getTime();
    });
  }, [pastWeekShifts]);

  // Filter shifts from weekStartDay to weekStartDay + 7
  // Do not add later date shifts if weekStartDay appeared twice in uniqueShifts
  const shifts = useMemo(() => {
    const datesAdded: any[] = [];
    const clippedShifts = sortedShifts.filter((shift: any) => {
      const shiftDate = new Date(shift.startDateTime);
      const indexOfDay = datesAdded.findIndex(
        (date) => date.day === shiftDate.getDay()
      );
      if (indexOfDay === -1) {
        datesAdded.push({
          day: shiftDate.getDay(),
          date: shiftDate.toDateString(),
        });
      } else {
        if (datesAdded[indexOfDay].date !== shiftDate.toDateString()) {
          return false;
        }
      }
      const dayIndex =
        (shiftDate.getDay() + 7 - getDayNumber(weekStartDay)) % 7; // Adjusted for start day
      return dayIndex >= 0 && dayIndex < 7;
    });
    return [...sortedPassedWeekShifts, ...clippedShifts]?.sort(
        (a: any, b: any) => {
            const aStart = new Date(a.startDateTime);
            const bStart = new Date(b.startDateTime);
            return aStart.getTime() - bStart.getTime();
        }
    );
  }, [sortedShifts, weekStartDay, sortedPassedWeekShifts]);

  const preProcessedShifts = useMemo(() => {
    let shiftsWithMargin: any[] = [];
    let returnValue = [];
    returnValue = shifts.map((shift: any) => {
      const startDate = new Date(shift.startDateTime);
      const endDate = new Date(shift.endDateTime);

      // Day index of shift from 0 to 6 based on company week start day
      const shiftDayIndex =
        (startDate.getDay() + 7 - getDayNumber(weekStartDay)) % 7;

      // Used for day overlapping shift rendering
      const shiftNextDayIndex =
        (startDate.getDay() + 8 - getDayNumber(weekStartDay)) % 7;

      // Index of shift start hour from
      const shiftHourIndex = startDate.getHours();

      // Index of shift end hour from 0 to 23
      // This value will be used to calculate from where shift bottom will start
      const edgeHour = endDate.getHours() - 1 < 0 ? 1 : 0;
      const shiftEndHourIndex = overlappingDaysShifts.some(
        (overlappingShift: any) => overlappingShift.id === shift.id
      )
        ? endDate.getHours() - 1 + edgeHour
        : -1;

      // Shift duration in minutes will help generating shift card height
      const shiftDurationInMinutes =
        (endDate.getTime() - startDate.getTime()) / (1000 * 60);

      // Shift height is calculated based on the shift duration
      const shiftHeight =
        (shiftDurationInMinutes / (24 * 60)) * (timeSlotHeight * 24);

      // Shift top is calculated based on the start time, used for shifts starting on same day
      const shiftTop = (startDate.getMinutes() / 60) * 100;

      // Shift bottom is calculated based on the end time, handled edge cases like 12 AM
      const shiftBottom = edgeHour ? ((60-endDate.getMinutes()) / 60) * 100 :
          shiftEndHourIndex !== -1 && edgeHour === 0 ?
            (-(endDate.getMinutes()) / 60) * 100
              : ((endDate.getMinutes())/ 60) * 100;

      // Find overlapping shifts
      const overlappingShifts = shifts.filter((otherShift: any) => {
        const otherStartTime = new Date(otherShift.startDateTime).getTime();
        const otherEndTime = new Date(otherShift.endDateTime).getTime();
        const startDateTime = new Date(shift.startDateTime).getTime();
        const endDateTime = new Date(shift.endDateTime).getTime();
        return (
          (otherStartTime >= startDateTime && otherStartTime < endDateTime) ||
          (otherEndTime > startDateTime && otherEndTime <= endDateTime) ||
          (otherStartTime <= startDateTime && otherEndTime >= endDateTime)
        );
      });

      // Sort by start time
      overlappingShifts.sort(
        (a: any, b: any) =>
          new Date(a.startDateTime).getTime() -
          new Date(b.startDateTime).getTime()
      );

      // Position of shift in overlapping shifts
      const shiftIndexInOverlapping = overlappingShifts.findIndex(
        (s: any) => s.id === shift.id
      );

      // Default modifier for shift margin
      let modifierForShiftMargin = 1;

      // Default margin and width for overlapping shifts
      let overlappingShiftMargin = 50;
      let overlappingShiftWidth = 100;

      // Calculate shift width and margin
      const overlappingShiftsCount = overlappingShifts.length;
      let totalCountOfIndexesLessThanCurrentShiftIndex = 0;
      if (overlappingShiftsCount > 1) {
        // Total count of indexes less than the current shift index
        totalCountOfIndexesLessThanCurrentShiftIndex =
          overlappingShifts.filter(
            (overlappingShift: any, index) => index < shiftIndexInOverlapping
          ).length;
        // Loop through overlapping shifts to adjust margin modifier
        overlappingShifts.forEach((overlappingShift: any, index) => {
          if (overlappingShift.id === shift.id) {
            return;
          }
          const overlappingShiftIndexInOverlapping =
              overlappingShifts.findIndex(
                  (otherOverlappingShift: any) =>
                      overlappingShift.id === otherOverlappingShift.id
              ) ?? 0;
          if (overlappingShiftIndexInOverlapping !== -1) {
            if (overlappingShiftIndexInOverlapping < shiftIndexInOverlapping) {
              const isShiftsMarginAlreadySetForOverlappingShift =
                  shiftsWithMargin.findIndex(
                      (shiftWithMargin: any) =>
                          shiftWithMargin.id ===
                          overlappingShifts[overlappingShiftIndexInOverlapping].id
                  );
              if (isShiftsMarginAlreadySetForOverlappingShift === -1) {
                modifierForShiftMargin += 1;
              } else {
                const overlappingShift = shiftsWithMargin.find(
                    (shiftWithMargin: any) =>
                        shiftWithMargin.id ===
                        overlappingShifts[overlappingShiftIndexInOverlapping].id
                );
                overlappingShiftMargin = overlappingShift?.margin ?? 50;
                overlappingShiftWidth = overlappingShift?.width ?? 100;
                if (overlappingShiftMargin !== 50) {
                  modifierForShiftMargin =
                      overlappingShiftMargin / shiftSpacing + 1;
                } else if (
                    modifierForShiftMargin === 1 &&
                    index === totalCountOfIndexesLessThanCurrentShiftIndex - 1
                ) {
                  modifierForShiftMargin = -1;
                }
              }
            }
          }
        });
      }

      // Based on margin modifier position shift by updating margin
      let changedMarginOfShift =
        shiftIndexInOverlapping > 0
          ? 100 / overlappingShiftsCount < 50
            ? modifierForShiftMargin !== -1
              ? shiftSpacing * modifierForShiftMargin
              : 50
            : modifierForShiftMargin !== -1 && overlappingShiftMargin !== 0
            ? shiftSpacing * modifierForShiftMargin
            : overlappingShiftMargin === 0 && overlappingShiftWidth === 50
            ? 50
            : shiftSpacing
          : 0;

      // based on updated margin adjust width, handle 2 shift case to display 50% width each
      let shiftWidthPercent =
        overlappingShiftsCount > 1
          ? overlappingShiftMargin !== 50
            ? 100 - changedMarginOfShift
            : overlappingShiftsCount === 2
            ? 50
            : 100 - changedMarginOfShift
          : 100;

      // Reset to minimum width and margin if left margin goes beyond 60
      if (changedMarginOfShift > 100 - minShiftWidth) {
        changedMarginOfShift = 100 - minShiftWidth;
        shiftWidthPercent = minShiftWidth;
      }

      // Post-processing for width and margin
      if (totalCountOfIndexesLessThanCurrentShiftIndex === 1
          && changedMarginOfShift === 100 - minShiftWidth
      ) {
        // If there is just one overlapping shift before the current shift
        // Then check if both are in 4th row then move one to 1st row
        const indexOfOverlappingShiftInShiftsWithMargin =
            shiftsWithMargin.findIndex(
                (shiftWithMargin: any) =>
                    shiftWithMargin.id ===
                    overlappingShifts[shiftIndexInOverlapping - 1].id
            );
        if (shiftsWithMargin[indexOfOverlappingShiftInShiftsWithMargin].margin === shiftSpacing * 3) {
          changedMarginOfShift = 0;
          shiftWidthPercent = 100 - shiftSpacing;
        }
      } else {
        // Moved last overlapping shift card to empty space if available by
        // changing margin of the shift if there is no shift with 0 margin
        // and there is already a shift in 4th row
        let foundZeroMargin = false;
        let foundFourthRow = false;
        // List all overlapping shifts in changed margin
        const allOverlappingShiftsInChangedMargin = overlappingShifts.filter(
            (overlappingShift: any) => {
              const overlappingShiftIndexInOverlapping =
                  overlappingShifts.findIndex(
                      (otherOverlappingShift: any) =>
                          overlappingShift.id === otherOverlappingShift.id
                  ) ?? 0;
              return (
                  overlappingShiftIndexInOverlapping < shiftIndexInOverlapping
              );
            }
        );
        allOverlappingShiftsInChangedMargin.forEach(
            (overlappingShift: any, index) => {
              const indexOfOverlappingShiftInShiftsWithMargin =
                  shiftsWithMargin.findIndex(
                      (shiftWithMargin: any) =>
                          shiftWithMargin.id === overlappingShift.id
                  );
              if (indexOfOverlappingShiftInShiftsWithMargin !== -1) {
                if (shiftsWithMargin[indexOfOverlappingShiftInShiftsWithMargin].margin === 0 &&
                    new Date(shiftsWithMargin[indexOfOverlappingShiftInShiftsWithMargin].startDateTime).getTime()
                    > new Date(shift.startDateTime).getTime() - 2 * 60 * 60 * 1000
                ) {
                  foundZeroMargin = true;
                } else if (
                    shiftsWithMargin[indexOfOverlappingShiftInShiftsWithMargin].margin === shiftSpacing * 3) {
                  foundFourthRow = true;
                }
              }
            }
        );
        if (!foundZeroMargin && changedMarginOfShift === 100 - minShiftWidth && foundFourthRow) {
          // If there is no shift with 0 margin and there is already a shift in 4th row
          // Then move last overlapping shift card to empty space if available
          changedMarginOfShift = 0;
          shiftWidthPercent = minShiftWidth;
        }
      }

      // Check if we have set shift margin records for given shift
      const isShiftsMarginAlreadySet = shiftsWithMargin.findIndex(
        (shiftWithMargin: any) => shiftWithMargin.id === shift.id
      );

      if (isShiftsMarginAlreadySet === -1) {
        // If we haven't set yet then set it
        if (
          overlappingShiftWidth === 50 &&
          shiftWidthPercent !== 50 &&
          shiftIndexInOverlapping > 0
        ) {
          const indexOfOverlappingShiftInShiftsWithMargin =
            shiftsWithMargin.findIndex(
              (shiftWithMargin: any) =>
                shiftWithMargin.id ===
                overlappingShifts[shiftIndexInOverlapping - 1].id
            );
          shiftsWithMargin[
            indexOfOverlappingShiftInShiftsWithMargin
          ].width = 100;
        }

        shiftsWithMargin = [
          ...shiftsWithMargin,
          {
            id: shift.id,
            startDateTime: shift.startDateTime,
            margin: changedMarginOfShift,
            width: shiftWidthPercent,
            shiftIndexInOverlapping: shiftIndexInOverlapping,
            overlappingShiftsCount: overlappingShiftsCount,
          },
        ];
      }

      return {
        ...shift,
        shiftDayIndex,
        shiftHourIndex,
        shiftEndHourIndex,
        shiftNextDayIndex,
        shiftTop,
        shiftBottom,
        shiftWidthPercent,
        shiftHeight,
        shiftIndexInOverlapping,
        changedMarginOfShift,
      };
    });

    // Update shifts width based on already set shift margin records
    returnValue = returnValue.map((shift: any) => {
      const isShiftsMarginAlreadySet = shiftsWithMargin.findIndex(
        (shiftWithMargin: any) => shiftWithMargin.id === shift.id
      );
      if (isShiftsMarginAlreadySet !== -1) {
        const shiftWithMargin = shiftsWithMargin.find(
          (shiftWithMargin: any) => shiftWithMargin.id === shift.id
        );

        // Adjust shift card margin to display peak of card behind
        shift.shiftWidthPercent = shiftWithMargin.overlappingShiftsCount > 2 && shiftWithMargin.width >= 80 ? shiftWithMargin.width - shiftSpacing/4 :
            shiftWithMargin.width >= 60 ? shiftWithMargin.width - shiftSpacing/8 : shiftWithMargin.width;
        shift.changedMarginOfShift = shiftWithMargin.margin === 60 ?
            shiftWithMargin.margin - shiftWithMargin.shiftIndexInOverlapping > 50 ?
                shiftWithMargin.margin - shiftWithMargin.shiftIndexInOverlapping :
                shiftWithMargin.margin : shiftWithMargin.margin;
      }
      return shift;
    });

    return returnValue;
  }, [shifts, overlappingDaysShifts, weekStartDay, timeSlotHeight]);

  const processedShifts = useMemo(() => {
    // We did this to ensure that shift card behind larger shift card is visible
    return preProcessedShifts.sort((a: any, b: any) => {
      // Same time or within 2 hour range
      const withinOneHourRange = 120 * 60 * 1000;
        const aStart = new Date(a.startDateTime).getTime();
        const bStart = new Date(b.startDateTime).getTime();
      const checkCondition = Math.abs(aStart - bStart) < withinOneHourRange;
      if (checkCondition) {
        if (a.changedMarginOfShift === 0) {
          return -1;
        }
        if (b.changedMarginOfShift === 0) {
          return 1;
        }
        return b.shiftWidthPercent - a.shiftWidthPercent;
      }
      return new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime();
    });
  },[preProcessedShifts]);

  const calculateTodayPosition = () => {
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      const currentDay = addDays(startingDay, i);
      if (isSameDay(today, currentDay)) {
        return i;
      }
    }
    return -1; // Default if not found
  };

  const todayPosition = calculateTodayPosition();

  const [layerEl, setLayerEl] = useState<any>(null); // For handling overlapping shift popover on layer icon
  const isLayerPopoverOpen = Boolean(layerEl);
  const [selectedHourDateInfo, setSelectedHourDateInfo] = useState<any>(null);
  const [hourPassingShifts, setHourPassingShifts] = useState<any>([]);
  const openLayerPopover = (
      event: any,
      allPassingShifts:any,
      hourIndex: number,
      dayIndex: number,
      timeDateInfo: {
        hourText: string;
        dayText: string;
        dateText: string;
      },
  ) => {
    setSelectedHourDateInfo({
      hourText: timeDateInfo.hourText,
      dayText: timeDateInfo.dayText,
      dateText: timeDateInfo.dateText,
      stackedShiftsText: allPassingShifts?.length ?? 0,
      hourIndex: hourIndex,
      dayIndex: dayIndex,
    });
    setLayerEl(event.currentTarget);
    setHourPassingShifts(allPassingShifts);
  };
  const closeLayerPopover = () => {
    setLayerEl(null);
    setSelectedHourDateInfo(null);
    setHourPassingShifts([]);
  };

  const handleSelectionComplete = (index: number, startY: number, endY: number) => {
    // Create new shift with start and end time
    const day = addDays(startingDay, index);
    const startHour = Math.floor(startY / timeSlotHeight);
    const startMinutes = Math.floor((startY % timeSlotHeight) / timeSlotHeight * 60);
    const endHour = Math.floor(endY / timeSlotHeight);
    const endMinutes = Math.floor((endY % timeSlotHeight) / timeSlotHeight * 60);
    const startDate = new Date(day);
    const endDate = new Date(day);

    // Check if we are on same hour as of current time
    const currentTime = new Date();
    let sameHour = false;
    const anchorHour = startDate.getTime() > endDate.getTime() ? endDate.getHours() : startDate.getHours();
    if (isSameDay(day, currentTime) && anchorHour === currentTime.getHours()) {
      const currentMinutes = currentTime.getMinutes();
      if (startMinutes < currentMinutes) {
        sameHour = true;
      }
    }

    // Snap to nearest 30min in start and end time
    const startMinutesRounded = sameHour ? Math.ceil(startMinutes / 30) * 30 : Math.round(startMinutes / 30) * 30;
    const endMinutesRounded = Math.round(endMinutes / 30) * 30;

    startDate.setHours(startHour, startMinutesRounded, 0, 0);
    endDate.setHours(endHour, endMinutesRounded, 0, 0);
    if (startDate.getTime() > endDate.getTime()) {
      if ((startDate.getTime() - endDate.getTime()) / (1000 * 60) >= 30 && endDate.getTime() > new Date().getTime()) {
        openCreateNewShiftDialog({startDate: endDate, endDate: startDate});
      }
    } else {
      if ((endDate.getTime() - startDate.getTime()) / (1000 * 60) >= 30 && startDate.getTime() > new Date().getTime()) {
        openCreateNewShiftDialog({startDate, endDate});
      }
    }
  };

  return [
    {
      anchorEl,
      dayColumnRefs,
      selectedShift,
      currentTime,
      timeSlotHeight,
      weekStartDay,
      startingDay,
      currentDate,
      isWeekView,
      todayPosition,
      pastWeekShifts,
      processedShifts,
      shifts,
      userRoles,
      canCreateOrEditShift,
      visible,
      layerEl,
      isLayerPopoverOpen,
      selectedHourDateInfo,
      hourPassingShifts,
      calendarRef,
      defaultCalendarScrollValue,
      recentShiftScrollValue,
    },
    {
      toggleVisibility,
      setVisible,
      generateSkeletonVisibility,
      openShiftPopover,
      openLayerPopover,
      closeLayerPopover,
      isLastDayOfWeek,
      setRecentShiftScrollValue,
      handleSelectionComplete
    },
  ];
};

export default useGridSchedulerController;
