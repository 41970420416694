import { Box, Grid2 } from "@mui/material";
import { styled } from "@mui/material/styles";

import {dayHeaderHeight, gutterWidth, minDayColumnWidth} from "./constants";

export const DayColumnHeader = styled(Grid2)(({ theme }) => ({
  zIndex: 1000,
  minHeight: `${dayHeaderHeight}px`, // Ensure the component has a height
  "&:first-of-type": {
    borderLeft: "1px solid rgba(224,224,224)",
  },
  "&:last-of-type": {
    borderRight: "1px solid rgba(224,224,224)",
  },
  minWidth: `${minDayColumnWidth}px`, // Ensure the component has a min width
}));
export const DayColumn = styled(Grid2)(({ theme }) => ({
  borderLeft: "1px solid rgb(224,224,224)",
  position: "relative",
  minWidth: `${minDayColumnWidth}px`, // Ensure the component has a min width
}));

export const SelectionBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: `${minDayColumnWidth}px`,
  padding: "8px",
  backgroundColor: "#2F4D8B",
  borderRadius: "4px",
  opacity: 0.6,
  pointerEvents: "none", // Ensures it doesn't interfere with mouse interactions
  left: "2px",
}));

export const TimeSlotHeader = styled(Box)(({ theme, height }) => ({
  height: `${height}px`,
  minHeight: `${height}px`,
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  position: "relative",
  zIndex: 2,
  "&::after": {
    content: "\"\"",
    position: "absolute",
    top: -0.9,
    right: 0,
    width: "8px",
    borderBottom: "1px solid rgba(224,224,224)",
  },
  "&:last-of-type": {
    top: 1,
  },
}));

export const AvailabilityTimeSlotHeader = styled(Box)(({ theme, height }) => ({
  height: `${height}px`,
  minHeight: `${height}px`,
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  position: "relative",
  zIndex: 2,
  "&::after": {
    content: "\"\"",
    position: "absolute",
    top: -0.9,
    right: 0,
    width: "8px",
    borderBottom: "1px solid rgba(224,224,224)",
  },
}));

// Styled components
export const TimeSlot = styled(Box)(({ theme, height }) => ({
  borderBottom: "1px solid rgba(224,224,224)",
  height: `${height}px`,
  minHeight: `${height}px`,
  flex: 1,
  display: "flex",
  alignItems: "center",
  position: "relative",
  "&:last-child": {
    borderBottom: "none",
  },
}));

export const TimeLabel = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  width: "80px",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-end",
  height: "100%",
  zIndex: 2,
}));

// Update styled component to include a circle on the left
export const CurrentTimeIndicator = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  width: `calc(100% - ${gutterWidth}px)`,
  height: "2px",
  backgroundColor: "#366BBF",
  zIndex: 999,
  "&::before": {
    content: "\"\"",
    position: "absolute",
    left: "0px",
    top: "50%",
    transform: "translateY(-50%)",
    width: "12px",
    height: "12px",
    backgroundColor: "#366BBF",
    borderRadius: "50%",
  },
}));

export const DayHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  height: `${dayHeaderHeight}px`,
  maxHeight: `${dayHeaderHeight}px`,
  borderBottom: "1px solid #ddd",
}));
